'use client';

import { Button, Drawer, Input, Popover } from 'antd';
import cn from 'classnames';
import Image from 'next/image';
import RouterLink from 'next/link';
import { useRouter } from 'next/navigation';
import { useState } from 'react';

import BackIcon from '@/app/_icons/back-arrow.svg';
import CloseIcon from '@/app/_icons/close.svg';
import ContactWhiteIcon from '@/app/_icons/contact-white.svg';
import ContactIcon from '@/app/_icons/contact.svg';
import MenuIcon from '@/app/_icons/menu.svg';
import SearchIcon from '@/app/_icons/search.svg';
import { Header as HeaderType } from '@/app/_types/header';
import { getDirectusAssetURI } from '@/app/_utils/assets';

import ContactUs from './ContactUs';
import Styles from './Hamburger.module.scss';
import { SubMenu } from './SubMenu';

type HamburgerProps = HeaderType & {
  // any other props specific to the component go here
};

export const Hamburger = (props: HamburgerProps) => {
  const [isMenuOpenContact, setIsMenuOpenContact] = useState<boolean>(false);
  const [open, setOpen] = useState<boolean>(false);
  const [searchDrawer, setSearchDrawer] = useState(false);

  const router = useRouter();

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
    setSearchDrawer(false);
  };
  const showSearchDrawer = () => {
    // setSearchDrawer(true);
    router.push('/search-results');
    setOpen(false);
  };

  const onSearchDrawerClose = () => {
    setSearchDrawer(false);
  };

  return (
    <>
      <ul className={Styles.hamburgerMenuList}>
        <li>
          <Popover
            overlayClassName={'extendedMenu'}
            content={
              <ContactUs
                closeMenu={() => setIsMenuOpenContact(false)}
                assistTitle={props.assist_title}
                assistNumber={props.assist_number}
                claimsTitle={props.claims_title}
                claimsNumber={props.claims_number}
              />
            }
            trigger="click"
            arrow={false}
            placement="bottom"
            onOpenChange={setIsMenuOpenContact}
            open={isMenuOpenContact}
          >
            <Button
              type="text"
              className={cn(Styles.menuIconBtn, Styles.contactMenuIcon, {
                [Styles.menuIconBtnActive]: isMenuOpenContact,
              })}
            >
              <Image
                src={isMenuOpenContact ? ContactWhiteIcon : ContactIcon}
                alt="Contact"
                height={18}
                width={18}
              />
            </Button>
          </Popover>
        </li>
        <li>
          <Button
            type="text"
            className={Styles.menuIconBtn}
            onClick={showDrawer}
          >
            <Image src={MenuIcon} alt="Search" height={18} width={18} />
          </Button>
        </li>
      </ul>
      <Drawer
        onClose={onClose}
        open={open}
        className="extendedMenu"
        closable={false}
        size="large"
      >
        <div className={Styles.menuHeader}>
          <RouterLink href="/">
            <Image
              src={getDirectusAssetURI(props.main_logo) || ''}
              alt="Bryte Logo" //TODO: fetch image alt values from files API
              className={Styles.brandLogo}
              width={142}
              height={56}
            />
          </RouterLink>
          <Button
            type="text"
            className={Styles.menuIconCloseBtn}
            onClick={onClose}
          >
            <Image src={CloseIcon} alt="Contact" height={20} width={20} />
          </Button>
        </div>
        <div className={Styles.searchContainer}>
          <div className={Styles.searchBox}>
            <Input
              placeholder="Search..."
              className={cn(Styles.searchBoxInput, 'customInput')}
            />
            <Button className={Styles.searchBtn} onClick={showSearchDrawer}>
              <Image src={SearchIcon} alt="Search" height={14} width={14} />
            </Button>
          </div>

          <Drawer
            size="large"
            closable={false}
            onClose={onSearchDrawerClose}
            open={searchDrawer}
            className="extendedMenu"
          >
            <div className={cn(Styles.menuHeader, Styles.searchHeader)}>
              <RouterLink href="/">
                <Image
                  src={getDirectusAssetURI(props.main_logo) || ''}
                  alt="Bryte Logo"
                  className={Styles.brandLogo}
                  width={142}
                  height={56}
                />
              </RouterLink>
              <Button
                type="text"
                className={Styles.menuIconCloseBtn}
                onClick={onClose}
              >
                <Image src={CloseIcon} alt="Contact" height={20} width={20} />
              </Button>
            </div>

            <div className={Styles.searchResultBox}>
              <div className={Styles.searchResultHeader}>
                <Button
                  type="text"
                  onClick={onSearchDrawerClose}
                  className={Styles.backBtn}
                >
                  <Image src={BackIcon} alt="Back" height={14} width={14} />
                  Back
                </Button>
              </div>

              <ul className={Styles.searchResultList}>
                <li>
                  <a href="#" className={Styles.searchResultListLink}>
                    Result 1
                  </a>
                </li>
                <li>
                  <a href="#" className={Styles.searchResultListLink}>
                    Result 2
                  </a>
                </li>
                <li>
                  <a href="#" className={Styles.searchResultListLink}>
                    Result 3
                  </a>
                </li>
                <li>
                  <a href="#" className={Styles.searchResultListLink}>
                    Result 4
                  </a>
                </li>
              </ul>

              <ul className={Styles.searchSuggestionList}>
                <li>
                  <Button className={Styles.suggestionBtn}>Suggestion 1</Button>
                </li>
                <li>
                  <Button className={Styles.suggestionBtn}>Suggestion 2</Button>
                </li>
                <li>
                  <Button className={Styles.suggestionBtn}>
                    Longer Suggestion 3{' '}
                  </Button>
                </li>
              </ul>
            </div>
          </Drawer>
        </div>

        <SubMenu
          closeHamburger={onClose}
          buttons={props.buttons}
          items={props.nav_link}
          staticItems={props.static_navigation_links}
        />
      </Drawer>
    </>
  );
};
