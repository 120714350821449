'use client';

import { RightOutlined } from '@ant-design/icons';
import { Menu, MenuProps } from 'antd';
import cn from 'classnames';
import Link from 'next/link';
import { useState } from 'react';

import { Navigation } from '@/app/_types/components/navigation';
import { NavigationM2A } from '@/app/_types/footer';

import Styles from './FooterMenuMobile.module.scss';

type Props = {
  items: NavigationM2A[];
};

type MenuItem = Required<MenuProps>['items'][number];

interface LevelKeysProps {
  key?: string;
  children?: LevelKeysProps[];
}

const FooterMenuMobile = ({ items }: Props) => {
  const [stateOpenKeys, setStateOpenKeys] = useState<string[]>([]);

  const transformMenuItem = (key: string, item: Navigation): MenuItem => {
    return {
      key,
      label: item.permalink ? (
        <Link
          href={item.permalink}
          className={cn(
            Styles.menuLink,
            item.text === 'See All' && Styles.seaAllLink,
          )}
        >
          {item.text}
          <div className={'menuIcon'}>
            <RightOutlined />
          </div>
        </Link>
      ) : (
        <span className={Styles.menuLink}>{item.text}</span>
      ),
      children: item.children
        ? transformSubMenuData(item.nav_children ? item.nav_children : [])
        : undefined,
    };
  };

  const transformMenuData = (items: NavigationM2A[]) => {
    return items.map(
      (itemM2A: NavigationM2A): MenuItem =>
        transformMenuItem(String(itemM2A.id), itemM2A.item),
    );
  };

  const transformSubMenuData = (items: Navigation[]) => {
    return items.map(
      (item: Navigation): MenuItem => transformMenuItem(String(item.id), item),
    );
  };

  const menuItems: MenuItem[] = transformMenuData(items);

  const getLevelKeys = (items1: LevelKeysProps[]) => {
    const key: Record<string, number> = {};
    const func = (items2: LevelKeysProps[], level = 1) => {
      items2.forEach((item) => {
        if (item.key) {
          key[item.key] = level;
        }
        if (item.children) {
          func(item.children, level + 1);
        }
      });
    };

    func(items1);
    return key;
  };
  const levelKeys = getLevelKeys(menuItems as LevelKeysProps[]);

  const onOpenChange: MenuProps['onOpenChange'] = (openKeys) => {
    const currentOpenKey = openKeys.find(
      (key) => stateOpenKeys.indexOf(key) === -1,
    );

    if (currentOpenKey !== undefined) {
      const repeatIndex = openKeys
        .filter((key) => key !== currentOpenKey)
        .findIndex((key) => levelKeys[key] === levelKeys[currentOpenKey]);

      setStateOpenKeys(
        openKeys
          .filter((_, index) => index !== repeatIndex)
          .filter((key) => levelKeys[key] <= levelKeys[currentOpenKey]),
      );
    } else {
      setStateOpenKeys(openKeys);
    }
  };

  return (
    <div className={Styles.subMenuWrapper}>
      <div className={Styles.subMenuContainer}>
        <Menu
          mode="inline"
          openKeys={stateOpenKeys}
          onOpenChange={onOpenChange}
          items={menuItems}
          className={Styles.subMenuList}
          expandIcon={''}
        />
      </div>
    </div>
  );
};

export default FooterMenuMobile;
