'use client';
import Image from 'next/image';
import RouterLink from 'next/link';
import Link from 'next/link';
import React, { useEffect, useState } from 'react';

import BackToTopImage from '@/app/_icons/back-to-top-icon.png';
import LinkedIn from '@/app/_icons/linkedin.svg';
import Twitter from '@/app/_icons/twitter.svg';
import { FilesMeta } from '@/app/_types/file';
import { Footer as FooterType, LinkM2A } from '@/app/_types/footer';
import { getAltProperty, getDirectusAssetURI } from '@/app/_utils/assets';

import ContactForm from './ContactForm';
import Styles from './Footer.module.scss';
import FooterMenu from './FooterMenu';
import FooterMenuMobile from './FooterMenuMobile';

type FooterProps = FooterType & {
  filesMeta?: FilesMeta;
};

const Footer = (props: FooterProps) => {
  const { filesMeta = {} } = props;

  const [showBackToTop, setShowBackToTop] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 300) {
        setShowBackToTop(true);
      } else {
        setShowBackToTop(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <footer className={Styles.footerWrapper}>
      <div className={Styles.topNav}>
        <div className="container">
          <div className={Styles.topNavContainer}>
            <RouterLink href="/">
              <Image
                src={getDirectusAssetURI(props.bryte_logo) || ''}
                alt={getAltProperty(filesMeta, props.bryte_logo, 'Bryte Logo')}
                className={Styles.brandLogo}
                width={142}
                height={56}
              />
            </RouterLink>

            <ul className={Styles.contactList}>
              <li>
                {props.assist_title}{' '}
                <a href={`tel:${props.assist_number}`}>{props.assist_number}</a>
              </li>
              <li>
                {props.claims_title}{' '}
                <a href={`tel:${props.claims_number}`}>{props.claims_number}</a>
              </li>
            </ul>

            <ul className={Styles.socialList}>
              {props.social_links?.length &&
                props.social_links.map((itemM2M) => {
                  const item = itemM2M.link_id;

                  return (
                    <li key={itemM2M.id}>
                      <a href={item.href} className={Styles.socialBtn}>
                        {item.image && (
                          <Image
                            src={getDirectusAssetURI(item.image) || ''}
                            alt={getAltProperty(
                              filesMeta,
                              item.image,
                              item.text,
                            )}
                            height={14}
                            width={14}
                          />
                        )}
                      </a>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      </div>
      <div className={Styles.bottomNavSection}>
        <div className="container">
          <div className={Styles.bottomContainer}>
            <div className={Styles.bottomLeftContent}>
              <div className={Styles.desktopNavigation}>
                <FooterMenu items={props.navigation_items} />
              </div>
              <div className={Styles.mobileNavigation}>
                <FooterMenuMobile items={props.navigation_items} />
                <ContactForm {...props.contact_form} />
              </div>

              <div className={Styles.copyrightSection}>
                <div className={Styles.copyrightDescription}>
                  {props.company_declaration}
                </div>
                <ul className={Styles.copyrightLinkList}>
                  {props.legal_links &&
                    props.legal_links.map((linkM2A: LinkM2A) => {
                      const item = linkM2A.item;

                      return (
                        <li key={linkM2A.id}>
                          <Link
                            href={item.href}
                            className={Styles.copyrightLink}
                          >
                            {item.text}
                          </Link>
                        </li>
                      );
                    })}
                </ul>
                <div className={Styles.mobileNavigation}>
                  <div className={Styles.footerBrandContainer}>
                    <RouterLink href="/">
                      <Image
                        src={getDirectusAssetURI(props.bryte_logo) || ''}
                        alt={getAltProperty(
                          filesMeta,
                          props.bryte_logo,
                          'Bryte Logo',
                        )}
                        className={Styles.brandLogo}
                        width={142}
                        height={56}
                      />
                    </RouterLink>

                    <ul className={Styles.socialList}>
                      <li>
                        <Link href="#" className={Styles.socialBtn}>
                          <Image
                            src={LinkedIn}
                            height={14}
                            width={14}
                            alt="LinkedIn"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link href="#" className={Styles.socialBtn}>
                          <Image
                            src={Twitter}
                            height={14}
                            width={14}
                            alt="Twitter"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className={Styles.copyrightContent}>
                &copy; {props.copyright_text}
              </div>
            </div>

            <div className={Styles.desktopNavigation}>
              <ContactForm {...props.contact_form} />
            </div>
          </div>
        </div>
      </div>
      <button
        className={`${Styles.backToTopBtn} ${showBackToTop ? Styles.show : ''}`}
        onClick={scrollToTop}
      >
        <Image src={BackToTopImage} alt="Back to Top" height={48} width={48} />
      </button>
    </footer>
  );
};

export default Footer;
