'use client';

import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { Form, message } from 'antd';
import { usePathname } from 'next/navigation';
import React, { useEffect, useState } from 'react';

import { Form as FormType } from '@/app/_types/components/form';
import { saveFormData } from '@/app/actions';

import ButtonCustom from '../button/ButtonCustom';
import FormInput from '../form/FormInput';

import Styles from './ContactForm.module.scss';

type ContactFormProps = FormType & {};

const ContactForm = (props: ContactFormProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  const toggleForm = () => setIsOpen(!isOpen);
  const [isFormValid, setIsFormValid] = useState(false);
  const values = Form.useWatch([], form);
  const [messageApi, contextHolder] = message.useMessage();
  const pathname = usePathname();
  const saveData = saveFormData.bind(null, pathname, props.submission_key);

  useEffect(() => {
    form
      .validateFields({ validateOnly: true })
      .then(() => setIsFormValid(true))
      .catch(() => setIsFormValid(false));
  }, [form, values]);

  const handleSubmit = async (data: any): Promise<boolean> => {
    const response = await saveData(data);

    if (!response.success) {
      messageApi.open({
        type: 'error',
        content: response.error,
        duration: 10,
      });
      return false;
    } else {
      form.resetFields();
      messageApi.open({
        type: 'success',
        content: props.submitted_paragraph,
        duration: 10,
      });
      return true;
    }
  };

  return (
    <div className={Styles.contactFormWrapper}>
      { contextHolder }
      <button className={Styles.contactFormTitle} onClick={toggleForm}>
        {props.title} {isOpen ? <MinusOutlined /> : <PlusOutlined />}
      </button>
      <div className={Styles.contactFormDescription}>{props.description}</div>
      {isOpen && (
        <Form
          className={Styles.contactForm}
          size="large"
          layout="vertical"
          form={form}
          onFinish={handleSubmit}
        >
          <div className={Styles.contactFormField}>
            {props.form_inputs.map((input) => (
              <FormInput key={input.id} input={input} form={form}/>
            ))}
          </div>
          <div className={Styles.acceptTerms}>{props.terms_and_conditions}</div>
          <div className="pr-mob-2 pr-6">
            <ButtonCustom
              htmlType="submit"
              fullWidth={true}
              btnText={props.submit_text}
              disabled={!form.isFieldsTouched() || !isFormValid}
            />
          </div>
        </Form>
      )}
    </div>
  );
};

export default ContactForm;
