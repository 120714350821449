import { Popover } from 'antd';
import cn from 'classnames';
import Link from 'next/link';
import React from 'react';

import Styles from './MegaMenu.module.scss';

type Props = {
  onVisibleChange: (visible: boolean) => void;
  visible: boolean;
  content: React.ReactNode;
  title: string;
  href: string;
};

const MegaMenu = ({
  onVisibleChange,
  visible,
  content,
  title,
  href,
}: Props) => {
  const onClickHandler = () => {
    onVisibleChange(false);
  };

  return (
    <span onClick={onClickHandler}>
      <Popover
        overlayClassName={'extendedMenu'}
        content={content}
        onOpenChange={onVisibleChange}
        open={visible}
        placement="bottom"
        arrow={false}
      >
        <Link
          href={href}
          className={cn(Styles.menuLink, {
            [Styles.menuLinkActive]: visible,
          })}
        >
          {title}
        </Link>
      </Popover>
    </span>
  );
};

export default MegaMenu;
