import Link from 'next/link';
import React from 'react';

import { NavigationM2A } from '@/app/_types/footer';

import Styles from './FooterMenu.module.scss';

type Props = {
  items: NavigationM2A[];
};

const FooterMenu = ({ items }: Props) => {
  return (
    <div className={Styles.footerMenuWrapper}>
      {items.map((itemM2A) => {
        const item = itemM2A.item;

        return (
          <div key={itemM2A.id} className={Styles.footerMenuItem}>
            <a href={item.permalink} className={Styles.footerMenuTitle}>
              {item.text}
            </a>
            {item.children && (
              <ul className={Styles.subMenu}>
                {item.nav_children &&
                  item.nav_children.map((childItem, childIndex) => (
                    <li key={childIndex}>
                      <Link
                        href={childItem.permalink}
                        className={Styles.subLink}
                      >
                        {childItem.text}
                      </Link>
                    </li>
                  ))}
              </ul>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FooterMenu;
