import { Divider, Flex } from 'antd';
import React, { useState } from 'react';

import { Navigation } from '@/app/_types/components/navigation';

import Styles from './MegaMenu.module.scss';

type Props = {
  items: Navigation[];
  staticItems?:
    | {
        id: number;
        collection: string;
        item: Navigation;
      }[]
    | null;
};

const StaticLinks = ({ items }: { items: Navigation[] }) => {
  return (
    <ul className={Styles.quickLinkList}>
      {items.map((item, index) => (
        <li key={index}>
          <a href={item.permalink}>{item.text}</a>
        </li>
      ))}
    </ul>
  );
};

const DesktopMenu = ({ items, staticItems }: Props) => {
  const popoverWidth = document.getElementsByTagName('body')[0].offsetWidth;

  const [seeAll, setSeeAll] = useState<{ [key: string]: boolean }>({});

  const toggleSeeAll = (index: number, e: React.MouseEvent) => {
    e.stopPropagation();
    setSeeAll((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const itemsWithoutChildren = items.filter((item: any) => !item.children);
  const itemsWithChildren = items.filter((item: any) => item.children);
  const hasStaticLinks = staticItems && staticItems.length > 0;

  return (
    <div className={Styles.megaMenuWrapper} style={{ width: popoverWidth }}>
      <Flex className={Styles.megaMenuContainer}>
        <div className={Styles.leftMenuContent}>
          {itemsWithChildren.map((item: Navigation, index) => {
            const isSeeAll = seeAll[index];
            const maxVisibleItems = isSeeAll ? item.nav_children.length : 7;

            return (
              <div key={index} className={Styles.menuListColumn}>
                <a href={item.permalink} className={Styles.menuListLink}>
                  {item.text}
                </a>
                {item.children && (
                  <ul className={Styles.subMenu}>
                    {item.nav_children
                      .slice(0, maxVisibleItems)
                      .map((childItem: Navigation, childIndex: number) => (
                        <li key={childIndex}>
                          <a
                            href={childItem.permalink}
                            className={Styles.subLink}
                          >
                            {childItem.text}
                          </a>
                        </li>
                      ))}

                    {item.nav_children.length > 7 && (
                      <li>
                        <button
                          className={Styles.seeAllLink}
                          onClick={(e) => toggleSeeAll(index, e)}
                        >
                          {isSeeAll ? 'See Less' : 'See All'}
                        </button>
                      </li>
                    )}
                  </ul>
                )}
              </div>
            );
          })}

          {itemsWithoutChildren.length > 0 && (
            <div className={Styles.menuListColumn}>
              {itemsWithoutChildren.map((item, index) => (
                <a
                  key={index}
                  href={item.permalink}
                  className={Styles.menuListLink}
                >
                  {item.text}
                </a>
              ))}
            </div>
          )}
        </div>

        {hasStaticLinks && (
          <div className={Styles.quickLinkContent}>
            {staticItems.map((itemM2A, index) => {
              const itemKey = itemM2A.id;
              const item = itemM2A.item;
              const isLast = index === staticItems.length - 1;

              return (
                <React.Fragment key={itemKey}>
                  <p className={Styles.quickLinkTitle}>{item.text}</p>
                  <StaticLinks items={item.nav_children} />
                  {!isLast && <Divider className={Styles.quickLinkDivider} />}
                </React.Fragment>
              );
            })}
          </div>
        )}
      </Flex>
    </div>
  );
};

export default DesktopMenu;
